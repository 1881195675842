import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import queryString from 'query-string';
import {
  Button,
} from 'components/ui';


import {
  SearchResultsFilters,
  SearchResultsProperties,
  SearchResultsMap,
  SearchResultsSignup,
} from 'components/sections';
import { Media, CloseButton, SlidingContainer,  Spacer } from 'components/ui';
import Layout from '../components/layout';
import SEO from '../components/seo';
import sc from 'config/theme';
import bp from 'config/responsive';
import request from 'utils/request';

const Container = styled.div`
  padding-top: 70px;
  min-height: 100vh;

  .searchBoxResult {
    position: relative;
    z-index: 3;

    .newHomes {
      .checkbox {
        display: none;
      }

      & > span {
        display: inline-flex;
        justify-content: inherit;

        ${bp.large} {
          display: none;
        }
      }
    }
  }
`;

const InnerContainer = styled.div`
  ${bp.large} {
    width: 60vw;
  }
`;

const FilterContainer = styled.div`
  background: ${sc.colors.main};
  padding: 0 70px;
  ${bp.mobile} {
    padding: 0 22px;

  }
  ${bp.tablet} {
    padding: 0 30px;
  }
`;

const ResultsContainer = styled.div`
  background: ${sc.colors.whiteDim};
  padding: 0 70px;
  ${bp.mobile} {
    padding: 0 22px;
    margin-top: -14px;
  }
  ${bp.tablet} {
    padding: 0 30px 60px;
  }
`;

const SpaceBanner = styled.div`
  padding-bottom: 40px;
`

const MapContainer = styled.div`
  width: ${({ expanded }) => (expanded ? '100vw' : `40%`)};
  height: calc(100vh - 70px);
  position: fixed;
  right: 0%;
  top: 70px;
  transition: 0.35s 0.05s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 8;
`;

const MobileMapContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  position: fixed;
  left: 100%;
  top: 70px;
  transition: 0.35s 0.05s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 10;
  opacity: 0;
  ${({ expanded }) =>
    expanded &&
    css`
      opacity: 1;
      left: 0%;
    `}
`;

const MapExpander = styled(Button)`
  &&& {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: -15px;
    background: white;
    z-index: 9;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    color: ${sc.colors.main};
    transition: 0.35s cubic-bezier(0.77, 0, 0.175, 1);
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0);
      margin: 0;
      transition: 0.35s cubic-bezier(0.77, 0, 0.175, 1);
    }
    ${({ expanded }) =>
      expanded &&
      css`
        left: 15px;
        i {
          transform: translate(-50%, -50%) rotate(-180deg);
        }
      `}
  }
`;

const CloseButtonContainer = styled.div`
  position: fixed;
  top: 85px;
  left: 15px;
  z-index: 11;
`;

const Loading = styled.div`
    position: fixed;
    background: #1f2d52;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.6;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
`

const LoadingOverlay = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #D9A547;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    animation: pulse 1s infinite ease;
  }

  @keyframes pulse {
    0%   { opacity: 0; transform: scale(0.2) }
    50% { opacity: 1; transform: scale(1.5) }
    100% { opacity: 0; transform: scale(0.2)}
  }
`


export default class SearchResultsPage extends PureComponent {
  state = {
    markers: [],
    expanded: false,
    currentHovered: null,
    form: {},
    signupOpen: false,
    results: '',
  };

  expandMap = () => this.setState(({ expanded }) => ({ expanded: !expanded }));

  handleEnter = current => this.setState({ currentHovered: current });

  getData = async parsed => {

    let str = '';
    Object.keys(parsed).forEach((key, index, arr) => {
      if (index >= arr.length - 1) {
        str += `${key}=${parsed[key]}`;
      } else {
        str += `${key}=${parsed[key]}&`;
      }
    });
    const url = `mapMarkers?${str}`;
    this.setState({ loading: true, error: false });


    
      const results  = await request(url+'&count=1');

      const  { data } = await request(url);


    if (parsed.page == "1"){

      this.setState({ loading: false, markers: data , results : results.data.results});

    }else{

      let a = this.state.markers;
      let newdata;
      //TODO DISAPEAR BUTTON
      if(! data.hasOwnProperty('error')){

        newdata = a.concat(data);

      }else{

        newdata = a;

      }

      this.setState({ loading: false, markers: newdata });
    }

  };

  getParams = () => {
    const parsed = queryString.parse(this.props.location.search);
    delete parsed[''];

    parsed.priceMin = parseInt(parsed.priceMin);
    parsed.priceMax = parseInt(parsed.priceMax);

    if (isNaN(parsed.rangeMax))
      parsed.rangeMax = 2000;
    if (isNaN(parsed.rangeMin))
      parsed.rangeMin = 0;

    parsed.rangeMax = parseInt(parsed.rangeMax);
    parsed.rangeMin = parseInt(parsed.rangeMin);

    if (parsed.bedrooms == 'null')
    parsed.bedrooms = "all";

    if (parsed.propertyType == 'null')
    parsed.propertyType = "property-type-0";

    const temp = parsed.more;
    if (temp == ""){
      parsed.more = [];
    }else{
      parsed.more = temp.split(',')
    }





    this.setState({ form: { ...parsed } });
    //this.setState({selects: parsed.more});

  };

  toggleSidebar = () => {
    const currentState = this.state.signupOpen;
    const nextState = !currentState;

    if (nextState) {
      window.closeModal && window.closeModal();
      window.closeModal = this.toggleSidebar;
    } else {
      window.closeModal = undefined;
    }

    this.setState({ signupOpen: nextState });
  }


  loadNextPage = () => {

    this.state.form.page = parseInt(this.state.form.page) +1;

    this.getData(this.state.form)

  };

  async componentDidMount() {
    try {
      this.getParams();
    } catch (err) {
      this.setState({ loading: false, error: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.form !== this.state.form) {
      this.state.form.page = 1;
      this.getData(this.state.form);
    }
    if (prevProps.location.search !== this.props.location.search) {
      this.getParams();
    }
  }

  render() {
    const { markers, expanded, currentHovered, signupOpen, results} = this.state;
    const {
      data: {
        pages: { searchResults },
      },
    } = this.props;

    return (
      <Layout noFooter>
        <SEO
          title="Search Results"
          keywords={[`gatsby`, `application`, `react`]}
        />

        <Container>
          {this.state.loading == true &&
          <Loading className="single-loading">
            <LoadingOverlay></LoadingOverlay>
          </Loading>
          }

          <InnerContainer>
            <FilterContainer className="searchBoxResult">
              <SearchResultsFilters
                content={searchResults.form}
                form = {this.state.form}
                handleExpandMap={this.expandMap}
                handleSignup={this.toggleSidebar}
                 results={results}
              />
            </FilterContainer>
            <ResultsContainer>

              <SearchResultsProperties
                handleEnter={this.handleEnter}
                markers={markers}
                htb = {this.state.form.htb}
              />
              {this.state.loading == false &&
              <Button moreresults fluid alt onClick={this.loadNextPage}>
               Load More
              </Button>
              }

            </ResultsContainer>
          </InnerContainer>
          <Media
            breakpoint={bp.large}
            renderIfTrue={() => (
              <MapContainer expanded={expanded}>
                <MapExpander
                  onClick={this.expandMap}
                  expanded={expanded}
                  icon="angle left"
                />
                <SearchResultsMap
                  currentHovered={currentHovered}
                  markers={markers}
                />
              </MapContainer>
            )}
            renderIfFalse={() => (
              <MobileMapContainer expanded={expanded}>
                <CloseButtonContainer>
                  <CloseButton onClick={this.expandMap} />
                </CloseButtonContainer>

                <SearchResultsMap
                  currentHovered={currentHovered}
                  markers={markers}
                />
              </MobileMapContainer>
            )}
          />
          <SlidingContainer closeButton fullscreenOverlay aboveNavbar isOpen={signupOpen}>
            <SearchResultsSignup />
          </SlidingContainer>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query SearchResultsPage {
    pages {
      searchResults {
        title
        form {
          offerTypes {
            lettings
            sales
          }
          defaultCost {
            min
            max
          }
          selects {
            propertyType {
              value
              text
            }
            bedrooms {
              value
              text
            }
            moreOptions {
              value
              label
            }
            moreOptionsLet{
              value
              label
            }
          }
          updateBtn
        }
        banner {
          title
          btn
        }
      }
    }
  }
`;
